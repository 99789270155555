<template>
  <div class="pcHeader-nav-wrap w100">
    <div class="pcHeader-nav-content main-width">
      <div class="van-row gutter-24 w100 pcHeader-nav-content-row">
        <div class="van-col span-16 pcHeader-nav-content-left text-al">
          <div class="van-row gutter-24 row-height">
            <div class="van-col span-8 row-height flex pcHeader-nav-content-left-logo-content blod">
              <img
                src="@/assets/images/logo.png"
                class="pcHeader-nav-content-left-logo"
              />
              ShortABC
            </div>
            <!-- <div class="van-col span-4 row-height">
              <a href="/">Home</a>
            </div> -->
            <!-- Uncomment below for more links -->
            <!-- <div class="van-col span-4 row-height">
              <a href="">App</a>
            </div>
            <div class="van-col span-4 row-height pointer">简体中文</div> -->
          </div>
        </div>
        <div class="van-col span-8 row-height flex">
          <!-- Uncomment below for search feature -->
          <!-- <div class="search-view flex relative">
            <img src="@/assets/images/search.png" class="icon-search" />
            <input type="text" v-model="searchQuery" class="search-view-input" placeholder="search"/>
            <img src="@/assets/images/close_s.png" class="icon-close absolute pointer" v-if="searchQuery" @click="clearSearch">
          </div> -->
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="mobileHeader-nav-wrap"></div> -->
</template>

<script>
export default {
  data() {
    return {
      searchQuery: ''
    };
  },
  methods: {
    clearSearch() {
      this.searchQuery = '';
    }
  }
};
</script>

<style lang="less">
.pcHeader-nav-wrap {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: #212121;
  .row-height {
    height: 70px;
    line-height: 70px;
  }
  .pcHeader-nav-content {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    &-left {
      color: #fff;
      line-height: 70px;
      &-logo-content {
        font-size: 1.5em;
      }
      &-logo {
        width: 50px;
        margin-right: 10px;
      }
    }
    .search-view {
      width: 250px;
      padding-right: 30px;
      height: 30px;
      border-radius: 30px;
      background: hsla(0, 0%, 100%, .08);
      .icon-search {
        width: 25px;
        height: 25px;
      }
      &-input {
        border: none;
        background: transparent;
        height: 20px;
        color: #fff;
        font-size: 13px;
      }
      .icon-close {
        width: 30px;
        height: 30px;
        right: 10px;
        top: 0px;
      }
    }
  }
  .pcHeader-nav-content-left-logo-content{
    margin:0 10px;
  }
}

/* 适配 H5 和 PC */
@media (max-width: 768px) {
  .pcHeader-nav-wrap {
    .pcHeader-nav-content {
      flex-direction: column;
      align-items: flex-start;
    }
    .search-view {
      width: 100%;
    }
  }
}
</style>
